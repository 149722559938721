import React, { useState } from "react";
import ContactModal from "../pages/Onboarding/ContactModal";
import LicensingAndDisModal from "../pages/Onboarding/LicensingAndDisModal";
import PrivacyPolicyModal from "../pages/Onboarding/PrivacyPolicyModal";

function Footer() {
  let [show1, setShow1] = useState(false);
  let [show2, setShow2] = useState(false);
  let [show3, setShow3] = useState(false);

  return (
    <div className="footer-container">
      <div className="left-footer">
        <img className="bbb-logo" src="/images/bbbLogo.png" alt="Logo" />
        <span className="copyright-info">
          © 2022 CheerLending, LLC NMLS# 1524325
        </span>
      </div>
      <div className="right-footer">
        <div className="link1" role="button" onClick={() => setShow1(true)}>
          Contact
        </div>
        <div className="link2" role="button" onClick={() => setShow2(true)}>
          Licensing & Disclosure
        </div>
        <div className="link3" role="button" onClick={() => setShow3(true)}>
          Privacy Policy
        </div>
      </div>
      <ContactModal onClose={() => setShow1(false)} show1={show1} />
      <LicensingAndDisModal onClose={() => setShow2(false)} show2={show2} />
      <PrivacyPolicyModal onClose={() => setShow3(false)} show3={show3} />
    </div>
  );
}

export default Footer;

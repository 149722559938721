import Loader from "./Loader";

function ProgressBar({ segmentCount, activeSegmentCount }) {
  let segments = [];
  for (let i = 0; i < segmentCount; i++) {
    const active = i < activeSegmentCount - 1;
    // const animate = i === activeSegmentCount - 2;
    segments.push(
      <Loader
        // className={`progress-bar-segment ${active ? "segment-" + i : ""}  ${
        //   animate ? "animate" : ""
        // }`}
        // key={`segment ${i}`}
        className={`progress-bar-segment ${active ? "segment-" + i : ""} `}
        key={`segment ${i}`}
      ></Loader>
    );
  }

  return <div className="progress-bar">{segments}</div>;
}

export default ProgressBar;

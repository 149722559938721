import React, { useRef, useState, useEffect } from "react";

/**
 * @param id the element id
 * @param className the classes for the element
 * @param type the type of input
 * @param disabled if the element should be disabled, defaults to false
 * @param small if the element should show in its "small" state
 * @param pattern the validation pattern, regex or string
 * @param placeholder the placeholder for the input
 * @param onChange a function called each time the value changes
 * @param onBlur a function called each time the input loses focus
 * @param children other elements nested inside
 * @param label the lable for the element
 * @param errorMessage the message to be displayed if validation isn't met
 * @param defaultVal the default value
 * @param autocomplete an array of JSX elements that will be displayed below the input component as autocomplete options
 * @returns an Input component
 */
function TextInput({
  id = "",
  className = "",
  type = "",
  disabled = false,
  small = false,
  pattern = ".*",
  placeholder = "",
  onChange = () => {},
  children = null,
  label = "",
  errorMessage = "",
  showErrorMessageWithoutError = false,
  defaultVal = "",
  hasError = false,
  setHasError = () => {},
  iconSrc = "",
  // iconAlt = "",
  minlength = "",
  maxLength = "",
  required = true,
  cashEntered = "",
  curComponent = "",
  onBlur = () => {},
  autocomplete = [],
}) {
  let [valid, setValidity] = useState(!hasError);
  let [value, setValue] = useState(defaultVal);
  let [hidePassword, setHidePassword] = useState(true);
  let [inactive, setInactive] = useState(false);
  let [inactiveTimer, setInactiveTimer] = useState();
  const input = useRef();
  const autocompleteRef = useRef();
  function setTimer() {
    return setTimeout(() => setInactive(true), 5000);
  }

  // Focus first text input on render
  useEffect(() => {
    document.querySelector(".text-input:not([disabled])")?.focus();
  }, []);

  // useEffect(() => {
  //   checkValidityOnBlur(input, setValidity, setHasError);
  // }, [])
  useEffect(() => {
    if (curComponent && defaultVal && defaultVal !== "") {
      setValue(
        Number(input.current.value.replace(/\D/g, "") || "").toLocaleString()
      );
      onChange({ target: input.current });
    } else {
      setValue(defaultVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVal]);

  useEffect(() => {
    setValidity(!hasError);
  }, [hasError]);

  return (
    <label
      className={
        "text-input-component " +
        className +
        (valid ? " valid " : " invalid ") +
        (disabled ? " disabled" : "")
      }
    >
      <span className="text-input-label">{label}</span>
      <div
        className={
          "text-input-wrapper" + (inactive ? " inactive" : "") + " " + className
        }
        onKeyDown={(e) => autoCompleteKeyboardControls(e)}
      >
        {cashEntered ? <div className="prefix">$</div> : ""}
        <input
          id={id}
          className={"text-input"}
          type={hidePassword ? type : "text"}
          pattern={pattern}
          placeholder={placeholder}
          onChange={(e) => {
            if (curComponent) {
              setValue(
                Number(e.target.value.replace(/\D/g, "") || "").toLocaleString()
              );
            } else {
              setValue(e.target.value);
            }
            clearTimeout(inactiveTimer);
            setInactive(false);
            setInactiveTimer(setTimer());
            onChange(e);
          }}
          onBlur={(e) => {
            checkValidityOnBlur(input, setValidity, setHasError);
            clearTimeout(inactiveTimer);
            setInactive(false);
            onBlur(e);
          }}
          onFocus={() => setInactiveTimer(setTimer())}
          disabled={disabled}
          ref={input}
          value={value || ""}
          required={required}
          minLength={minlength}
          maxLength={maxLength}
          style={{
            background: `url(${iconSrc}) no-repeat scroll 98% 50%`,
          }}
        ></input>
        <button
          className={"toggle-password " + (type !== "password" ? "hidden" : "")}
          onClick={() => {
            setHidePassword(!hidePassword);
            input.current.focus();
          }}
          type="button"
        >
          <img src="/images/Eye.svg" alt="Eye Logo" />
        </button>
        {autocomplete?.length > 0 ? (
          <datalist
            className="autocomplete-options"
            ref={autocompleteRef}
            tabIndex={-1}
          >
            {autocomplete}
          </datalist>
        ) : (
          ""
        )}
      </div>
      <span
        className={
          "input-error-message" +
          (showErrorMessageWithoutError
            ? " show-error-message-without-error"
            : "")
        }
      >
        {errorMessage}
      </span>
    </label>
  );

  function checkValidityOnBlur(input, setValidity, setHasError) {
    input = input.current;
    setValidity(input.validity.valid);
    setHasError(!input.validity.valid);
  }

  function autoCompleteKeyboardControls(e) {
    if (autocomplete.length === 0) return;
    const container = autocompleteRef.current;

    switch (e.key) {
      case "ArrowDown":
        //move focus
        e.preventDefault();
        if (document.activeElement.tagName === "INPUT") {
          container.children[0].focus();
        } else {
          if (document.activeElement.nextElementSibling)
            document.activeElement.nextElementSibling.focus();
          else container.children[0].focus();
        }
        break;

      case "ArrowUp":
        //move focus
        e.preventDefault();
        if (document.activeElement.tagName === "INPUT") {
          container.children[container.children.length - 1].focus();
        } else {
          if (document.activeElement.previousElementSibling)
            document.activeElement.previousElementSibling.focus();
          else container.children[container.children.length - 1].focus();
        }
        break;

      case "Enter":
        //move focus
        e.preventDefault();
        if (document.activeElement.tagName === "INPUT") break;
        document.activeElement.click();
        break;

      default:
        break;
    }
  }
}

export default TextInput;

import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signOut,
  deleteUser,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ajax } from "./Utility";

class FirebaseClass {
  constructor() {
    const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
  }

  addEvent(eventName, parameters = undefined) {
    if (process.env.NODE_ENV === "production") {
      logEvent(this.analytics, eventName, parameters);
    }
  }

  createUser(email, password) {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // ...
        // console.log('USER HERE =>', userCredential)
        logEvent(this.analytics, "sign_up");
        return userCredential;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(`Error code ${errorCode}: ${errorMessage}`);
        if (errorCode === "auth/email-already-in-use") {
          console.error("email exists.");
        }
        return error;
      });
  }

  deleteCurrentUser(dispatch) {
    let auth = getAuth();
    let user2 = auth.currentUser;
    deleteUser(user2);
    dispatch({
      type: "STORE_FIREBASE_USER",
      payload: {},
    });
  }

  async loginUser(email, password) {
    const auth = getAuth();
    try {
      await setPersistence(auth, browserLocalPersistence);
    } catch (error) {
      console.error("persistence error: ", error);
    }
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    try {
      return userCredential;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(`Error code ${errorCode}: ${errorMessage}`);
      throw error;
    }
  }

  async verifyToken() {
    const auth = getAuth();
    try {
      // passing in true will refresh the accessToken
      let token = await auth.currentUser.getIdToken(true);
      return token;
    } catch (error) {
      console.error(error);
    }
  }

  refreshUser(dispatch, callback = () => {}) {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("user");
        dispatch({
          type: "STORE_FIREBASE_USER",
          payload: user,
        });
        ajax({
          method: "POST",
          url: "/users/login",
          success: async (data) => {
            await this.getCurrentApplication(data, user, dispatch);
            callback(data, user);
          },
          error: (e) => console.error("failed to get user: ", e),
          data: JSON.stringify({
            firebaseToken: user.accessToken,
          }),
        });
      }
    });
    return unsubscribe;
  }

  async getCurrentApplication(userData, firebaseUser, dispatch) {
    dispatch({
      type: "STORE_USER_DATA",
      payload: userData,
    });
    return ajax({
      method: "POST",
      url: `/application/${userData.applications[0]}`,
      success: (data) => {
        dispatch({
          type: "STORE_APPLICATION_DATA",
          payload: data,
        });
      },
      error: (e) => console.error("failed to get application data: ", e),
      data: JSON.stringify({
        firebaseToken: firebaseUser.accessToken,
      }),
    });
  }

  async logoutUser() {
    const auth = getAuth();
    try {
      await signOut(auth);
    } catch (error) {
      console.error("there was an error signing out: ", error);
    }
  }

  checkForUser() {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(!!user);
        } else {
          resolve(!!user);
        }
      });
    });
  }

  async checkForEmail(email) {
    // checks whether an email exists already in firebase
    const auth = getAuth();
    try {
      const getUserEmail = fetchSignInMethodsForEmail(auth, email);
      const res = await getUserEmail;
      if (res.length < 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getImage(googleStoragePath) {
    const storage = getStorage();
    try {
      const url = await getDownloadURL(ref(storage, googleStoragePath));
      return url;
    } catch (err) {
      console.error(err);
    }
  }

  async uploadImage(image, uid) {
    const storage = getStorage();
    const storageRef = ref(storage, `users/${uid}/${image.name}`);

    uploadBytesResumable(storageRef, image, { contentType: image.type });
  }

  async deleteImage(image, uid) {
    const storage = getStorage();
    const storageRef = ref(storage, `users/${uid}/${image}`);
    try {
      await deleteObject(storageRef);
    } catch (error) {
      console.error("Error deleting image: ", error);
    }
  }

  resetPassword(email) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        console.error(`error ${error.code}: `, error);
        // ..
      });
  }
}

const Firebase = new FirebaseClass();

export default Firebase;

import React from "react";
import { useEffect, useState } from "react";

function LoadingCircleSVG({
  className = "",
  loadingTime = 1,
  noCheck = "",
  infinite = false,
  fasterLoad = false,
}) {
  let [displayClassName, setDisplayClassName] = useState(className);

  useEffect(() => {
    setDisplayClassName(className);
  }, [className]);
  if (noCheck) {
    return (
      <svg
        width="15%"
        height="15%"
        viewBox="0 0 104 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`loading-circle ${displayClassName}`}
      >
        {/* Grey background circle */}
        <circle
          cx="52"
          cy="52"
          r="48"
          className="loading-circle-grey"
          transform="rotate(-90 52 52)"
        />
        <circle
          cx="52"
          cy="52"
          r="48"
          className={`${
            fasterLoad ? "loading-completed" : "loading-circle-blue"
          }`}
          transform="rotate(-90 52 52)"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="15%"
        height="15%"
        viewBox="0 0 104 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`loading-circle ${displayClassName}`}
      >
        {/* Grey background circle */}
        <circle
          cx="52"
          cy="52"
          r="48"
          className="loading-circle-grey"
          transform="rotate(-90 52 52)"
        />
        <circle
          cx="52"
          cy="52"
          r="48"
          className={`${
            fasterLoad ? "loading-completed" : "loading-circle-blue"
          } ${infinite ? "infinite" : ""}`}
          transform="rotate(-90 52 52)"
        />

        {/* Animated circle */}
        <circle
          cx="52"
          cy="52"
          r="48"
          className="loading-circle-loaded"
          transform="rotate(-90 52 52)"

          // style={{
          //   transition: `stroke-dasharray ${loadingTime - 0.5}s ease-in-out`,
          // }}
        />

        {/* Success checkmark */}
        <path
          d="M76.8438 36L43.6144 67.7188L27 51.8594"
          className="loading-circle-check"
        />

        {/* Error X */}
        <path d="M68 36L36 68" className="loading-error-x" />
        <path d="M68 68L36 36" className="loading-error-x" />
      </svg>
    );
  }
}

export default LoadingCircleSVG;

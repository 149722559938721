import React from "react";
import MetaDecorator from "../../components/MetaDecorator";
import Modal from "../../components/Modal";

function PrivacyPolicyModal(props) {
  if (!props.show3) {
    return null;
  }

  return (
    // <div className="modal-container" onClick={props.onClose}>
    <Modal onClose={props.onClose}>
      <MetaDecorator
        title="Privacy Policy | California Rate Reduction"
        description="California Rate Reduction Privacy Policy"
      />

      <iframe
        title="Privacy Policy"
        src={
          "https://drive.google.com/viewerng/viewer?embedded=true&url=" +
          window.location.host +
          "/pdf/Cheerlending Privacy Policy.pdf"
        }
        style={{
          width: "100%",
          height: "100%",
          minHeight: "500px",
          border: "none",
        }}
      ></iframe>
    </Modal>
    // </div>
  );
}

export default PrivacyPolicyModal;

import React, { useContext } from "react";
import { StateContext } from "../contexts/State";
import TextCascade from "./TextCascade";

function FinancialGoalsFooter() {
  const { state } = useContext(StateContext);

  return state.currentApplication.financialGoals?.length > 0 ? (
    <footer className="financial-goals-footer">
      <b>Financial Goals</b>
      <TextCascade
        checks
        sections={state.currentApplication.financialGoals}
        waitTime={0}
      />
    </footer>
  ) : (
    ""
  );
}

export default FinancialGoalsFooter;

export const firebaseReducer = (state, action) => {
  switch (action.type) {
    case "STORE_FIREBASE_USER":
      return action.payload;
    default:
      return state;
  }
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case "STORE_USER_DATA":
      return action.payload;
    default:
      return state;
  }
};

export const applicationReducer = (state, action) => {
  switch (action.type) {
    case "STORE_APPLICATION_DATA":
      return action.payload;
    default:
      return state;
  }
};

import { useEffect, useState, useCallback } from "react";
import LoadingCircleSVG from "./svg/LoadingCircleSVG";
import { useHistory } from "react-router-dom";

function LoadingScreen({
  loadingMessage = "",
  successMessage = "",
  errorMessage = "",
  status = "",
  successPath = "",
  errorPath = "",
  noCheck = "",
  classNameDisplay = "",
  data,
  fasterLoad = false,
  timeoutDuration = 4000,
}) {
  let [className, setClassName] = useState("");
  let [displayMessage, setDisplayMessage] = useState(loadingMessage);
  let [rerouteSuccess, setRerouteSuccess] = useState("");
  let [rerouteError, setRerouteError] = useState("");

  const history = useHistory();

  const setPath = useCallback(() => {
    setTimeout(() => {
      if (status === "success") {
        setClassName("success");
        setDisplayMessage(successMessage);
      } else {
        setClassName("error");
        setDisplayMessage(errorMessage);
      }
    }, timeoutDuration);
    setTimeout(() => {
      redirect();
      function redirect() {
        if (status === "success") {
          history.push({ pathname: rerouteSuccess, state: data });
        }
        if (status === "error") {
          history.push(rerouteError);
        }
      }
    }, timeoutDuration + 2000);
  }, [
    errorMessage,
    rerouteError,
    rerouteSuccess,
    history,
    status,
    successMessage,
    data,
    timeoutDuration,
  ]);

  useEffect(() => {
    setPath();
    setRerouteSuccess(successPath);
    setRerouteError(errorPath);
  }, [rerouteError, rerouteSuccess, errorPath, successPath, setPath]);

  return (
    <div className="success-failure-container">
      <LoadingCircleSVG
        className={className}
        fasterLoad={fasterLoad}
        loadingTime="5"
        noCheck={noCheck}
      ></LoadingCircleSVG>
      <h2 className={classNameDisplay}>{displayMessage}</h2>
    </div>
  );
}

export default LoadingScreen;

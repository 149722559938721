// Static Imports
import { useState, useEffect, useContext, Suspense } from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import BenefitLinks from "../../components/BenefitLinks";
import Footer from "../../components/Footer";
import AffiliateFooter from "../../components/AffiliateFooter";
import RouteRequiresLogin from "../../components/RouteRequiresLogin";
import { StateContext } from "../../contexts/State";
import { useHistory } from "react-router";
import { loginRedirect } from "../../services/Utility";
import Firebase from "../../services/firebase";
import Button from "../../components/Button";
import { lazyWithPrefetch as lazy } from "../../services/lazyWithPrefetch"; // NOT React.lazy()

// Static Import pages
import OnboardingInvitationCode from "./OnboardingInvitationCode";
import Error404 from "../Error404";
import FinancialGoalsFooter from "../../components/FinancialGoalsFooter";

// Dynamic import pages
const OnboardingZipCode = lazy(() => import("./OnboardingZipCode"));
const OnboardingName = lazy(() => import("./OnboardingName"));
const OnboardingPhoneNumber = lazy(() => import("./OnboardingPhoneNumber"));
const OnboardingEmail = lazy(() => import("./OnboardingEmail"));
const OnboardingPassword = lazy(() => import("./OnboardingPassword"));
// const OnboardingSignup = lazy(() => import("./OnboardingSignup"));
const OnboardingWhatToExpect = lazy(() => import("./OnboardingWhatToExpect"));
const BasicQuestions = lazy(() => import("./BasicQuestions/BasicQuestions"));
const MortgageStatement = lazy(() => import("./MortgageStatement"));
const ValidateEligibility = lazy(() =>
  import("../../components/ValidateEligibility")
);
const OnboardingCalendly = lazy(() => import("./OnboardingCalendly"));
const OnboardingCalendlyConfirmation = lazy(() =>
  import("./OnboardingCalendlyConfirmation")
);
// const Quiz = lazy(() => import("./Quiz"));
// const OnboardingKnowledgeLevel = lazy(() =>
//   import("./OnboardingKnowledgeLevel")
// );
// const OnboardingKnowledgeLevelOne = lazy(() =>
//   import("./OnboardingKnowledgeLevelOne")
// );
// const OnboardingKnowledgeLevelTwo = lazy(() =>
//   import("./OnboardingKnowledgeLevelTwo")
// );
// const OnboardingKnowledgeLevelThree = lazy(() =>
//   import("./OnboardingKnowledgeLevelThree")
// );
// const OnboardingKnowledgeLevelFour = lazy(() =>
//   import("./OnboardingKnowledgeLevelFour")
// );

function Onboarding() {
  const match = useRouteMatch();
  const [affiliate, setAffiliate] = useState({});
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(StateContext);
  const history = useHistory();
  const [isUser, setIsUser] = useState(false);
  const [checkForUser, setCheckForUser] = useState("no user");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!state.user.emailAddress) {
      const affStorage = localStorage.getItem("affiliate");
      if (!affiliate.formattedAff && affStorage) {
        setAffiliate(JSON.parse(affStorage));
      }
    } else {
      localStorage.removeItem("affiliate");
    }
  }, [state.user.emailAddress, affiliate.formattedAff]);

  useEffect(() => {
    if (checkForUser === "signup") {
      return;
    } else {
      if (state.firebaseUser.uid) {
        setIsUser(true);
      } else {
        setIsUser(false);
      }
    }
  }, [state.firebaseUser.uid, checkForUser]);

  const fallback = ""; /* (
    <div className="onboarding">
      <main className="onboarding-window">
        <div className="onboarding-content"></div>
      </main>
      <Footer />
    </div>
  ); */

  return (
    <div className="onboarding">
      <Switch>
        <Route exact path={[`/onboarding`, `${match.url}/zip-code`]}>
          <Header />
        </Route>
      </Switch>

      <Switch>
        <Route
          exact
          path={[
            `/onboarding`,
            `${match.url}/zip-code`,
            `${match.url}/email`,
            `${match.url}/password`,
            `${match.url}/financial-goals`,
            // `${match.url}/knowledge-is-power`,
            // `${match.url}/whiteboard-video`,
            // // `${match.url}/quiz`,
            // `${match.url}/knowledge-level`,
            // `${match.url}/knowledge-level-one`,
            // `${match.url}/knowledge-level-two`,
            // `${match.url}/knowledge-level-three`,
            // `${match.url}/knowledge-level-four`,
          ]}
        >
          {isUser && (
            <Modal noCloseButton={true}>
              <div className="logged-in-modal">
                {state.user.firstName && (
                  <h1>Welcome Back, {state.user.firstName}!</h1>
                )}
                <p>Looks like you're already logged in.</p>
                <p>Click 'Continue' to resume your session, or logout.</p>
                <div className="logged-in-buttons-container">
                  <Button
                    onClick={() => {
                      loginRedirect(
                        state.user,
                        state.currentApplication,
                        history
                      );
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="secondary"
                    onClick={() => {
                      Firebase.logoutUser();
                      dispatch({ type: "REMOVE_ALL_DATA" });
                      history.push("/onboarding");
                    }}
                  >
                    Log Out
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </Route>
      </Switch>

      <main className="onboarding-window">
        <Suspense fallback={fallback}>
          <Switch>
            <Route path={`${match.url}/zip-code`}>
              <OnboardingZipCode
                setAffiliate={setAffiliate}
                affiliate={affiliate}
              />
            </Route>
            <Route path={`${match.url}/what-to-expect`}>
              <OnboardingWhatToExpect
                affiliate={affiliate}
                numComplete={2}
              ></OnboardingWhatToExpect>
            </Route>
            <RouteRequiresLogin path={`${match.url}/name`}>
              <OnboardingName />
            </RouteRequiresLogin>
            <RouteRequiresLogin path={`${match.url}/phone`}>
              <OnboardingPhoneNumber />
            </RouteRequiresLogin>
            <Route path={`${match.url}/email`}>
              <OnboardingEmail />
            </Route>
            <Route path={`${match.url}/password`}>
              <OnboardingPassword
                affiliate={affiliate}
                setCheckForUser={setCheckForUser}
              />
            </Route>
            <Route path={`${match.url}/validate`}>
              <ValidateEligibility affiliate={affiliate} />
            </Route>
            <RouteRequiresLogin path={`${match.url}/basic-questions`}>
              <BasicQuestions />
            </RouteRequiresLogin>
            <RouteRequiresLogin path={`${match.url}/calendly`}>
              <OnboardingCalendly />
            </RouteRequiresLogin>
            <RouteRequiresLogin path={`${match.url}/calendly-confirmation`}>
              <OnboardingCalendlyConfirmation />
            </RouteRequiresLogin>
            <RouteRequiresLogin path={`${match.url}/mortgage`}>
              <MortgageStatement />
            </RouteRequiresLogin>
            <Route exact path={`/onboarding/`}>
              <OnboardingInvitationCode setAffiliate={setAffiliate} />
            </Route>
            <Route>
              <Error404 />
            </Route>
          </Switch>
          <Switch>
            <Route exact path={[`/onboarding`, `${match.url}/zip-code`]}>
              <BenefitLinks />
            </Route>

            <Route path={`${match.url}/validate`}>{/* Nothing here */}</Route>
            <Route path={`/onboarding/basic-questions/financial-goals`}>
              <AffiliateFooter affiliate={affiliate} />
            </Route>
            <Route path="/onboarding/basic-questions">
              <FinancialGoalsFooter />
            </Route>
            <Route path={`/onboarding/`}>
              <AffiliateFooter affiliate={affiliate} />
            </Route>
          </Switch>
        </Suspense>
      </main>
      <Footer />
    </div>
  );
}

export default Onboarding;

import React from "react";
import MetaDecorator from "../../components/MetaDecorator";
import Modal from "../../components/Modal";

function ContactModal(props) {
  if (!props.show1) {
    return null;
  }

  return (
    // <div className="modal-container" onClick={props.onClose}>
    <Modal onClose={props.onClose}>
      <MetaDecorator
        title={"Contact | California Rate Reduction"}
        description={"CheerLending Contact Information"}
      />
      <div onClick={(e) => e.stopPropagation()}>
        {/* <div className="modal-header">
          <h4>Contact</h4>
        </div> */}
        <div className="footer-modal-body">
          <p className="main">
            We serve the entire state of California. It’s best to schedule your
            initial appointment with a Home Loan Advisor through the
            <span className="blue-font"> www.californiaratereduction.com </span>
            website, however you may contact us by phone or email as well.
          </p>
          <p className="phone">PHONE: 1-800-478-8619</p>
          <p className="email">
            EMAIL: <span className="blue-font">Contact@CheerLending.com</span>
          </p>
        </div>
        {/* <div className="modal-footer"> */}
        {/* <button type="button" className="button" onClick={props.onClose}>
            Close
          </button> */}
        {/* </div> */}
      </div>
    </Modal>
    // </div>
  );
}

export default ContactModal;

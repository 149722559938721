import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Onboarding from "./pages/Onboarding/Onboarding";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Error404 from "./pages/Error404";

export default function Routes() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/onboarding">
          <Onboarding />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/">
          <Redirect to="/onboarding" />
        </Route>
        <Route>
          <Error404 />
        </Route>
      </Switch>
    </Router>
  );
}

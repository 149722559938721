import { useCallback } from "react";
import { useHistory } from "react-router-dom";

function InvitationCode({
  id = "",
  className = "",
  type = "",
  disabled = false,
  onClick = () => {},
  target = "",
  children = null,
}) {
  const history = useHistory();
  const navigate = useCallback(() => {
    if (!disabled && target) {
      history.push(target);
    }
  }, [disabled, target, history]);

  return (
    <button
      id={id}
      className={`button-component ${disabled ? "disabled" : ""} ${className}`}
      type={type}
      value="submit"
      disabled={disabled}
      onClick={
        disabled
          ? () => {}
          : async () => {
              try {
                await onClick();
                navigate();
              } catch (e) {
                console.error("Error in onclick", e);
                return;
              }
            }
      }
    >
      {children}
    </button>
  );
}

export default InvitationCode;

import React from "react";

function CheckCircleSVG({ className = "" }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`check-circle ${className}`}
    >
      <path
        d="M16.125 9.75L10.625 15L7.875 12.375"
        className="check-circle-check"
      />
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        className="check-circle-circle"
      />
    </svg>
  );
}

export default CheckCircleSVG;

import React from "react";
import Button from "../components/Button";
import MetaDecorator from "../components/MetaDecorator";
import Footer from "../components/Footer";
import { useHistory } from "react-router";

function Error404() {
  const history = useHistory();

  return (
    <div className="onboarding">
      <main className="onboarding-window">
        <div className="onboarding-content">
          <MetaDecorator title="404" description="Page Not Found" />
          <h1>Error 404</h1>
          <h2>You are attempting to access a page that does not exist.</h2>
          <Button onClick={history.goBack}>Go Back</Button>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Error404;

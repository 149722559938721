import { React, useState, useEffect } from "react";
import Button from "../../components/Button";
import ProgressBar from "../../components/ProgressBar";
import TextInput from "../../components/TextInput";
import MetaDecorator from "../../components/MetaDecorator";
import { ajax } from "../../services/Utility";
// import { useHistory } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
// import { element } from "prop-types";
import Firebase from "../../services/firebase";
import LoadingCircleSVG from "../../components/svg/LoadingCircleSVG";

function OnboardingInvitationCode({ setAffiliate }) {
  let [inputValue, setInputValue] = useState("");
  let [hasError, setHasError] = useState(false);
  let [loading, setLoading] = useState(false);
  let [wasSuccess, setWasSuccess] = useState(false);
  let [wasFailure, setWasFailure] = useState(false);
  let [successRoute, setSuccessRoute] = useState("");
  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(!showModal);
  };
  // const history = useHistory();

  useEffect(() => {
    Firebase.addEvent("invitation_screen_opened");
  }, []);

  if (!loading && !wasSuccess && !wasFailure) {
    return (
      <form
        className="onboarding-invitation-code onboarding-content"
        onSubmit={submitCode}
      >
        <MetaDecorator
          title="Invitation Code | California Rate Reduction"
          description="CheerLending, offering personalized mortgage solutions, fast customized quotes, great rates, & service with integrity."
          keywords="Mortgage, Quotes, Rates, Service, California, Arizona, Florida"
        />
        <h1>Rate Reduction Initiative</h1>
        <ProgressBar segmentCount="6" activeSegmentCount="2" />
        <h2>Discount Home Loan Rates for California Homeowners</h2>

        <TextInput
          id="invitation-code"
          label="Enter Your Invitation Code:"
          placeholder="Type invitation code here"
          onChange={(e) => {
            setInputValue(e.target.value);
            //sets back to false once user types new input
            setHasError(false);
          }}
          errorMessage="Invalid invitation code. Please try again."
          hasError={hasError}
          setHasError={setHasError}
        />
        <aside className={hasError ? "error-subtext" : "subtext"}>
          An invitation code is required to access our discounted rates.{" "}
          <span className="small-screen-hidden">
            <br />
          </span>
          Need access?{" "}
          {/* Need access? changed path to see CSS changes. link to visited */}
          <button onClick={onClose} type="button">
            Click here{" "}
          </button>{" "}
          to request one.
        </aside>
        {showModal && (
          <Modal onClose={onClose}>
            <div style={{ textAlign: "center" }}>
              <h2>
                <b>Don't have an invitation code?</b>
              </h2>
              <p>
                You'll need to request one in order to access our discounted
                rates.
              </p>
              <h2 style={{ margin: "2em 0" }}>
                Call <a href="tel:1-800-478-8619">1-800-478-8619</a>
                <br />
                Press option 2
              </h2>
              <aside>[Click on the above phone number to dial]</aside>
            </div>
          </Modal>
        )}
        <Button
          id="submit-button"
          className="primary"
          target={null}
          disabled={!inputValue || hasError}
          type="submit"
        >
          Validate Code
        </Button>
      </form>
    );
    // will enter if there was a success or failure
  } else if (loading && !wasSuccess && !wasFailure) {
    return (
      <div className="onboarding-content">
        <div className="success-failure-container">
          <LoadingCircleSVG infinite={true} />
          <h2>Verifying Invitation Code</h2>
        </div>
      </div>
    );
  } else if (wasSuccess || wasFailure) {
    return (
      <div className="onboarding-success onboarding-content">
        {/* Inner ternary determines which loading screeen to return */}
        {wasSuccess ? (
          <LoadingScreen
            // Pass in status
            // Pass in success/failure redirect links
            // Pass in overall timing for loader
            loadingMessage="Verifying Invitation Code"
            successMessage="Invitation Code Validated"
            // errorMessage="Invalid Error Message"
            // status=""
            status="success"
            successPath={successRoute}
            // errorPath="/onboarding"
            data={inputValue}
          />
        ) : (
          <LoadingScreen
            // Pass in status
            // Pass in success/failure redirect links
            // Pass in overall timing for loader
            loadingMessage="Verifying Invitation Code"
            // successMessage="Invitation Code Validated"
            errorMessage="Invalid Invitation Code"
            // status=""
            status="error"
            // successPath="/onboarding/zip-code"
            errorPath="/onboarding"
          />
        )}
      </div>
    );
  }

  async function submitCode(e) {
    e.preventDefault();
    setLoading(true);

    const onSuccess = function (response) {
      response = response.json().then((res) => {
        if (response && response.status === 400) {
          onFailure();
          setHasError(true);
        } else {
          setWasSuccess(true);
          setSuccessRoute("/onboarding/zip-code");
        }
      });
    };

    const onFailure = function () {
      setWasFailure(true);
      setHasError(true);
    };

    const data = new FormData();
    let formattedVal = inputValue.toUpperCase().split(" ").join("");
    setAffiliate({
      formattedAff: formattedVal,
      rawCodeEntered: inputValue,
    });
    localStorage.setItem(
      "affiliate",
      JSON.stringify({
        formattedAff: formattedVal,
        rawCodeEntered: inputValue,
      })
    );
    data.append("affCode", formattedVal);

    // Start displaying the loading screen
    let today = new Date();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    await ajax({
      method: "POST",
      url: "/affCode/verify",
      success: onSuccess,
      failure: onFailure,
      data: data,
    });
    Firebase.addEvent("affiliate_code_entered", {
      affiliate_code: formattedVal,
    });
    Firebase.addEvent("affiliate_code_submitted");
    Firebase.addEvent("webflow_started_timestamp", {
      time: time,
    });
  }
}

export default OnboardingInvitationCode;

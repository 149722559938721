import React, { useEffect, useCallback, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Firebase from "../services/firebase";

const RouteRequiresLogin = (props) => {
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkForUser = useCallback(async () => {
    let isUser = await Firebase.checkForUser();
    setUserIsLoggedIn(isUser ? true : false);
    setLoading(false);
  }, []);

  useEffect(() => {
    checkForUser();
  }, [checkForUser]);
  return (
    <>
      {loading === false ? (
        <Route {...props}>
          {userIsLoggedIn ? props.children : <Redirect to="/login" />}
        </Route>
      ) : (
        <></>
      )}
    </>
  );
};
export default RouteRequiresLogin;

import { useEffect, useState } from "react";
import CheckCircleSVG from "./svg/CheckCircleSVG";

/**
 *
 * @param sections the array of strings and/or JSX objects that will be displayed in sequence
 * @param waitTime the time, in ms, between each section appearing
 * @param callback a function to be called once all the strings have been displayed. It is called "in rhythm," one `waitTime` after the last string appears
 * @param checks if `true`, the list will be displayed with a checkmark next to each option
 * @param numComplete if `checks` is `true`, the number of options that should be displayed as complete
 */
function TextCascade({
  id = "",
  className = "",
  sections = [],
  waitTime = 0,
  callback = () => {},
  checks = false,
  numComplete = 0,
}) {
  let [animateIndex, setAnimateindex] = useState(0);

  useEffect(() => {
    if (animateIndex <= sections.length)
      setTimeout(() => {
        setAnimateindex(++animateIndex);
      }, waitTime);
    else callback();
  }, [animateIndex, sections.length, waitTime, callback]);

  return (
    <ul
      id={id}
      className={`cascade-container${className ? " " + className : ""}${
        checks ? " checks" : " no-checks"
      }${!waitTime ? " no-fade" : ""}`}
    >
      {sections.map((section, i) => {
        return (
          <li
            className={`cascade-list ${i < animateIndex ? "active" : ""} ${
              checks && i < numComplete ? "complete" : ""
            }`}
            key={i}
          >
            {checks && (
              <div className="check-circle-container">
                <CheckCircleSVG></CheckCircleSVG>
              </div>
            )}

            <div className="section-text" id={i}>
              {section}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
export default TextCascade;

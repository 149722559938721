import React from "react";
import MetaDecorator from "../../components/MetaDecorator";
import Modal from "../../components/Modal";

function LicensingAndDisModal(props) {
  if (!props.show2) {
    return null;
  }

  return (
    // <div className="modal-container" onClick={props.onClose}>
    <Modal onClose={props.onClose}>
      <MetaDecorator
        title="Liscensing & Disclosure | California Rate Reduction"
        description="California Rate Reduction Liscensing & Disclosure"
      />
      <div onClick={(e) => e.stopPropagation()}>
        {/* <div className="modal-header">
          <h4>Licensing & Disclosure</h4>
        </div> */}
        <div className="footer-modal-body">
          <p className="main">
            Cheerlending, LLC NMLS # 1524325, Licensed by the California
            Department of Business Oversight # 60DBO-59500, Arizona Mortgage
            Broker Lic # MB-0949257, Florida Mortgage Broker Lic # MBR3789
          </p>
          <p className="exceptions">
            *Certain loan programs are eligible for no appraisal
          </p>
        </div>
        {/* <div>
          <button type="button" className="button" onClick={props.onClose}>
            Close
          </button>
        </div> */}
      </div>
    </Modal>
    // </div>
  );
}

export default LicensingAndDisModal;

import React, { createContext, useReducer, useEffect } from "react";
import { applicationReducer, firebaseReducer, userReducer } from "./reducer";
import Firebase from "../../services/firebase";

const INITIAL_STATE = {
  firebaseUser: {},
  user: {},
  currentApplication: {},
};

const StateContext = createContext({
  state: INITIAL_STATE,
  dispatch: () => null,
});

const stateReducer = ({ firebaseUser, user, currentApplication }, action) => {
  switch (action.type) {
    case "REMOVE_ALL_DATA":
      return INITIAL_STATE;
    default:
      return {
        firebaseUser: firebaseReducer(firebaseUser, action),
        user: userReducer(user, action),
        currentApplication: applicationReducer(currentApplication, action),
      };
  }
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, INITIAL_STATE);
  let unsubscribe = () => {};

  useEffect(() => {
    // eslint-disable-next-line
    unsubscribe = Firebase.refreshUser(dispatch);
  }, []);

  useEffect(() => {
    unsubscribe();
    // eslint-disable-next-line
    unsubscribe = () => {};
  }, [state.firebaseUser]);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };

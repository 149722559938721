import React, {useEffect, useState, useContext} from "react";
import Button from "../components/Button";
import MetaDecorator from "../components/MetaDecorator";
import TextInput from "../components/TextInput";
import BackButton from "../components/BackButton";
import Firebase from "../services/firebase";
import {ajax, loginRedirect} from "../services/Utility";
import {useHistory} from "react-router";
import BenefitLinks from "../components/BenefitLinks";
import Footer from "../components/Footer";
import {StateContext} from "../contexts/State";

function Login() {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [filled, setFilled] = useState(false);
  let [validationError, setValidationError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [emailErrorMsg, setEmailErrorMsg] = useState(
    "Please enter a valid email address."
  );
  let [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const {state, dispatch} = useContext(StateContext);

  const history = useHistory();

  useEffect(() => {
    if (email !== "" && password !== "") {
      setFilled(true);
    }
  }, [setFilled, email, password, filled]);

  return (
    <div className="onboarding">
      <main className="onboarding-window">
        <form
          className="onboarding-login onboarding-content"
          onSubmit={(e) => {
            e.preventDefault();
            login(email, password);
          }}
        >
          <MetaDecorator
            title="Login | California Rate Reduction"
            description="Login to existing account"
          />
          <BackButton pathname={`/onboarding`} />
          <h1>Member Login</h1>
          <h2>Welcome back</h2>
          <fieldset>
            <TextInput
              id="email-input-field"
              label="Email Address"
              type="email"
              placeholder="Email Address"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
                setValidationError(false);
                setEmailErrorMsg("Please enter a valid email address.");
              }}
              minlength="5"
              errorMessage={emailErrorMsg}
              hasError={emailError || validationError}
              setHasError={setEmailError}
            />
            <TextInput
              id="password-input-field"
              label="Password"
              type="password"
              placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
              onChange={(e) => {
                setPassword(e.target.value);
                setValidationError(false);
              }}
              hasError={validationError}
              errorMessage={passwordErrorMsg}
            />
          </fieldset>
          <div className="button-container">
            <Button
              disabled={!filled || emailError || validationError}
              id="submit-button"
              type="submit"
            >
              Login
            </Button>
            <Button className="flat" target={"reset-password"} type="button">
              Forgot your password?
            </Button>
          </div>
        </form>
        <BenefitLinks />
      </main>
      <Footer />
    </div>
  );

  async function login(email, password) {
    try {
      const credential = await Firebase.loginUser(email, password);
      // if (state.firebaseUser.uid) {
      //   await dispatch({ type: "REMOVE_ALL_DATA" });
      // }
      dispatch(Object.assign(state.firebaseUser, credential.user), {
        type: "STORE_FIREBASE_USER",
      });
      ajax({
        method: "POST",
        url: "/users/login",
        success: getApplicationData,
        error: loginFailed,
        data: JSON.stringify({
          firebaseToken: credential.user.accessToken,
        }),
      });
    } catch (error) {
      console.error(error);
      loginFailed(error);
    }
  }

  function getApplicationData(data) {
    dispatch(Object.assign(state.user, data), {type: "STORE_USER_DATA"});
    ajax({
      method: "POST",
      url: `/application/${data.applications[0]}`,
      success: successfulRedirect,
      error: loginFailed,
      data: JSON.stringify({
        firebaseToken: state.firebaseUser.accessToken,
      }),
    })
      .then(() => null)
      .catch((error) => {
        console.error(error);
      });
  }
  //Commented out original paths for reference
  function successfulRedirect(data) {
    dispatch(Object.assign(state.currentApplication, data), {
      type: "STORE_APPLICATION_DATA",
    });
    loginRedirect(state.user, data, history);
  }

  function loginFailed(response) {
    setPasswordErrorMsg("Your email or password was incorrect");
    setEmailErrorMsg("");
    setValidationError(true);
    console.error(response);
  }
}

export default Login;

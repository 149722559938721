import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

const MetaDecorator = ({ title, description, keywords = null }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string,
};

MetaDecorator.defaultProps = {
  keywords: null,
};

export default MetaDecorator;

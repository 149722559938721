import { useState } from "react";
import Firebase from "./firebase";

/**
 * Creates, sends, and handles an XMLHttpRequest
 * @param {string} method the type of HttpRequest (GET, POST, etc.)
 * @param {string} url the URL to send the request to
 * @param {function} success the optional function called when the request is successful. First parameter is the response body
 * @param {function} failure the optional function called when the request is successful. First parameter is the response body
 * @param {JSON} data the data being included with the request
 */
export async function ajax({
  method,
  url,
  success = function (response) {},
  failure = function (response) {},
  data,
}) {
  // return fetch("https://Cheerlending-be-stage.herokuapp.com/api" + url, {
  let inspUrl = "";
  if (
    url.includes("/update") ||
    url.includes("/save_mortgage_statement") ||
    url.includes("/complete")
  ) {
    inspUrl = buildInspUrl();
    let newToken = await checkToken(data);
    data = newToken;
  }

  let response;
  // if there is NO inspectlet URL built
  if (inspUrl === "") {
    response = await fetch(process.env.REACT_APP_BACKEND_URL + url, {
      method: method,
      mode: "cors",
      headers: {
        accept: "application/json",
        "Access-Control-Request-Method": method,
      },
      body: data,
    });
    // if there IS an inspectlet URL built
  } else {
    let parsed = JSON.parse(data);
    const temp = JSON.stringify({ ...parsed, inspUrl: inspUrl });
    response = await fetch(process.env.REACT_APP_BACKEND_URL + url, {
      method: method,
      mode: "cors",
      headers: {
        accept: "application/json",
        "Access-Control-Request-Method": method,
      },
      body: temp,
    });
  }

  try {
    const contentType = response.headers.get("content-type");
    if (response.status !== 200) throw response;
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then((data) => {
        return success(data);
      });
    } else {
      return success(response);
    }
  } catch (error) {
    console.error(error);
    failure(error);
  }
}

async function checkToken(data) {
  let temp = JSON.parse(data);
  if (temp.firebaseToken) {
    let newToken = await Firebase.verifyToken();
    temp.firebaseToken = newToken;
    return JSON.stringify(temp);
  } else {
    console.error("no token provided with request.");
  }
}

export function submitFormOnEnter() {
  window.addEventListener("keypress", (e) => {
    if (e.code === 13 || e.which === 13) {
      document.getElementById("submit-button").click();
    }
  });
}

/**
 * A function to force an update for deep state modifications
 */
export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(() => value + 1); // update the state to force render
}

// export function getCookie(propertyName) {
//   let cookie = document.cookie;
//   cookie = cookie.split(";");
//   let cookieObj = {};
//   cookie.forEach((cookieString) => {
//     let [cookieName, cookieValue] = cookieString.split("=");
//     cookieObj[cookieName] = cookieValue;
//   });

//   return cookieObj[propertyName];
// }

const getCookies = () => {
  const cookies = document.cookie.split(";").filter((cookie) => {
    if (cookie.includes("__insp_wid") || cookie.includes("__insp_sid")) {
      return cookie;
    } else {
      return null;
    }
  });
  const inspCookies = cookies.map((cookie) => {
    let temp = cookie.split("=");
    const obj = {
      name: temp[0],
      value: temp[1],
    };
    return obj;
  });
  return inspCookies;
};

const buildInspUrl = () => {
  const [wid, sid] = getCookies();
  if (wid && sid) {
    return `http://www.inspectlet.com/dashboard/watchsession/${wid.value}/${sid.value}`;
  } else {
    return "";
  }
};

/**
 * @param  {object} data the response object, which will be a user.
 * @param  {object} history useHistory, must be imported on the component used on.
 */
export function loginRedirect(user, data, history) {
  if (!user.firstName) {
    history.push("/onboarding/name");
  } else if (!user.mobilePhoneNumber) {
    history.push("/onboarding/phone");
  } else if (data.financialGoals == null) {
    history.push("/onboarding/basic-questions/what-to-expect");
  } else if (data.levelOfKnowledge == null) {
    history.push("/onboarding/basic-questions/level-of-knowledge");
  } else if (data.propertyAddress.line1 == null) {
    history.push("/onboarding/basic-questions/physical-address");
  } else if (data.propertyType == null) {
    history.push("/onboarding/basic-questions/property-type");
  } else if (data.additionalProperties == null) {
    history.push("/onboarding/basic-questions/additional-properties");
  } else if (
    !data.othersOnCurrentLoan ||
    data.othersOnCurrentLoan.length === 0
  ) {
    history.push("/onboarding/basic-questions/people-on-current-loan");
  } else if (!data.othersOnNewLoan || data.othersOnNewLoan.length === 0) {
    history.push("/onboarding/basic-questions/people-on-new-loan/edit");
  } else if (
    data.othersOnNewLoan.length > 1 &&
    data.othersOnNewLoan[1].relationship == null
  ) {
    history.push("/onboarding/basic-questions/future-loan-relationships");
  } else if (data.isVeteran === null || undefined) {
    history.push("/onboarding/basic-questions/veteran-status");
  } else if (
    data.othersOnNewLoan.length >= 1 &&
    data.othersOnNewLoan[0].income.length === 0
  ) {
    history.push("/onboarding/basic-questions/income-sources");
  } else if (
    data.othersOnNewLoan.length >= 1 &&
    data.othersOnNewLoan[0].income[0].amount == null
  ) {
    history.push("/onboarding/basic-questions/income-details");
  } else if (!data.assets || data.assets.length < 1) {
    history.push("/onboarding/basic-questions/asset-types");
  } else if (!data.assets[0].amount) {
    history.push("/onboarding/basic-questions/asset-details");
  } else if (
    data.othersOnNewLoan.length >= 1 &&
    data.othersOnNewLoan[0].residence === null
  ) {
    history.push(`/onboarding/basic-questions/status-of-residence`);
  } else if (
    data.othersOnNewLoan.length >= 1 &&
    data.othersOnNewLoan[0].dob === "None"
  ) {
    history.push(`/onboarding/basic-questions/dob`);
    // } else if (data.mortgageStatements.length === 0) {
    //   history.push(`/onboarding/mortgage`);
  } else if (
    data.othersOnNewLoan.length >= 1 &&
    data.othersOnNewLoan[0].ssn === "***-**-****"
  ) {
    history.push(`/onboarding/basic-questions/ssn`);
  } else {
    history.push(`/onboarding/calendly`);
  }
}

import React, { useContext } from "react";
import Button from "../components/Button";
import { StateContext } from "../contexts/State";
import Firebase from "../services/firebase";

function Header() {
  const { state, dispatch } = useContext(StateContext);

  return (
    <div className="header-container">
      <div className="left-header">
        <p>Powered by:</p>
        <img
          src="/images/cheerlending-logo.png"
          className="companyLogo"
          alt="Company Logo"
        />
      </div>
      <div className="right-header">
        {state.firebaseUser.uid ? (
          <Button
            id="login-button"
            target={`/`}
            onClick={() => {
              Firebase.logoutUser();
              dispatch({ type: "REMOVE_ALL_DATA" });
            }}
          >
            Logout
          </Button>
        ) : (
          <Button id="login-button" target={`/login`}>
            Login
          </Button>
        )}
      </div>
    </div>
  );
}

export default Header;

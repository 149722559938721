import React from "react";
/**
 * @param  onClose
 * @param  noCloseButton
 */
const Modal = ({ onClose, noCloseButton, children, className }) => {
  return (
    <>
      <div className="backdrop" onClick={onClose}></div>

      <section className={`${className ? className : ""} modal`}>
        {!noCloseButton && (
          <button className="modal-button" type="button" onClick={onClose}>
            <img src="/images/modalX.svg" alt="" />
          </button>
        )}
        <main>{children}</main>
      </section>
    </>
  );
};
export default Modal;

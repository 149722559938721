import React, { useState, useEffect, useContext, useCallback } from "react";
import Firebase from "../services/firebase";
import { StateContext } from "../contexts/State";

function AffiliateFooter({ affiliate }) {
  const [img, setImg] = useState("");
  const { state } = useContext(StateContext);

  const fetchImg = useCallback(async () => {
    if (state.user.affCode) {
      let imgUrl = state.user.affCode.affImage;
      let getImage = await Firebase.getImage(imgUrl);
      setImg(getImage);
    } else if (affiliate.affImage) {
      let getImage = await Firebase.getImage(affiliate.affImage);
      setImg(getImage);
    }
  }, [affiliate.affImage, state.user.affCode]);

  useEffect(() => {
    fetchImg();
  }, [fetchImg]);

  return (
    <div>
      {state.user.affCode && state.user.affCode.affImage !== "" ? (
        <div className="affiliate-container">
          {state.user.affCode && state.user.affCode.affMessage && (
            <div>
              <h3 className="affiliate-message">
                {state.user.affCode.affMessage}
              </h3>
              {img ? (
                <img
                  src={img}
                  className="affiliate-image"
                  alt={state.user.affCode.affMessage}
                />
              ) : (
                <img className="affiliate-image" alt="" />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="affiliate-container">
          {affiliate && affiliate.affMessage && (
            <div>
              <h3 className="affiliate-message">{affiliate.affMessage}</h3>
              {img ? (
                <img
                  src={img}
                  className="affiliate-image"
                  alt={affiliate.affMessage}
                />
              ) : (
                <img className="affiliate-image" alt="" />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AffiliateFooter;

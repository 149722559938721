import React, { useState } from "react";
import Button from "../components/Button";
import MetaDecorator from "../components/MetaDecorator";
import TextInput from "../components/TextInput";
import BackButton from "../components/BackButton";
import Firebase from "../services/firebase";
import BenefitLinks from "../components/BenefitLinks";
import Footer from "../components/Footer";
import LoadingScreen from "../components/LoadingScreen";
import Header from "../components/Header";

function ResetPassword() {
  let [email, setEmail] = useState("");
  let [error, setError] = useState(false);
  let [submitted, setSumbitted] = useState(false);

  return (
    <div className="onboarding">
      <Header />
      <main className="onboarding-window">
        {!submitted ? (
          <form className="reset-password onboarding-content">
            <MetaDecorator
              title="Password Reset"
              description="Reset your password"
            />
            <BackButton pathname={`/login`} />
            <h1>Reset your password</h1>
            <h2>Enter the email for your account:</h2>
            <fieldset>
              <TextInput
                id="email-input-field"
                type="email"
                placeholder="Email Address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                setHasError={setError}
                errorMessage="Please enter a valid email"
              />
            </fieldset>
            <Button
              disabled={!email || error}
              onClick={() => {
                Firebase.resetPassword(email);
                setSumbitted(true);
              }}
            >
              Reset Password
            </Button>
          </form>
        ) : (
          <div className="onboarding-success onboarding-content">
            <LoadingScreen
              successMessage={
                <span>
                  Password reset email sent.
                  <br />
                  Please check your inbox.
                </span>
              }
              status="success"
            />
          </div>
        )}
        <BenefitLinks />
      </main>
      <Footer />
    </div>
  );
}

export default ResetPassword;

import React from "react";
import { useHistory, Link } from "react-router-dom";

function BackButton({ pathname = "", label = "" }) {
  const history = useHistory();
  return (
    <div className="back-button-container">
      {label ? (
        <label className="back-button">{label}</label>
      ) : (
        <>
          {pathname === "" ? (
            <span
              onClick={() => {
                history.goBack();
              }}
              style={{ textDecoration: "none" }}
            >
              <img
                className="back-button-image"
                src="/images/ArrowLeft.svg"
                alt="Back button"
              />
              <label className="back-button">Previous Page</label>
            </span>
          ) : (
            <Link
              to={{ pathname: pathname }}
              style={{ textDecoration: "none" }}
            >
              <img
                className="back-button-image"
                src="/images/ArrowLeft.svg"
                alt="Back button"
              />
              <label className="back-button">Previous Page</label>
            </Link>
          )}
        </>
      )}
    </div>
  );
}

export default BackButton;

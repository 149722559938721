import React from "react";

//  Information displayed was taken off website for filler.
//  Will need to confirm if information needs to be updated.

function BenefitLinks() {
  return (
    <div className="benefits-footer-container">
      <div className="benefits1 benefits-title">Reduce Interest Rate</div>

      <div className="benefits2 benefits-title">Reduce Monthly Payment</div>

      <div className="benefits3 benefits-title">No Home Appraisal</div>

      {/* Display is controlled by CSS */}
      <div className="benefit1 benefits-info">
        On average, home loan interest rates are reduced by over 1%.
      </div>
      <div className="benefit2 benefits-info">
        Over 96% of homeowners that complete the rate reduction process save at
        least one thousand dollars per year on their home loan payments.
      </div>
      <div className="benefit3 benefits-info">
        It’s usually best to skip the expense and anxiety that comes along with
        home appraisals. However, if a homeowner chooses to have an appraisal
        performed, we’ll rebate the full cost of such to the homeowner after the
        loan funds (up to $750).
      </div>
    </div>
  );
}

export default BenefitLinks;
